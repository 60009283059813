import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Store from '../../helpers/Store';
import Table from '../../screens/tablePaginadorGruposPartidos';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import Nav from '../../screens/nav';
import { faWindowClose,faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListaAlumnos from './lista_alumnos_partidos';
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

let id=0

const App=(props)=>{
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);



  const getInit=()=>{
    let data        =   {
                          token:queryStringParams.token,
                        }
    Functions.PostAsync("Puente","gruposPartidos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
    }
  }


  useEffect(() => {
    getInit()
  },[open])


  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Grupo",
        value:"label",
      },
      {
        label:"Partidos",
        value:"num_partidos",
      },
      {
        label:"Excel",
        value:"exportar",
        url:Config.ConfigApirest+"PDF/estatisticasPartidos?token="+queryStringParams.token+"&",
      },
      {
        label:"Gestionar",
        value:"editar",
      },
  ]

  return  <><div className="container-fluid">
            <div className="row pt-2">
              {
                open?<div className="col-12 mb-3">
                        <div className=" text-right mb-3"><div className="btn btn-primary" onClick={()=>setOpen(false)}>Cerrar</div></div>
                        <div><ListaAlumnos open={open}/></div>
                      </div>:false
              }
              {
                !open?<div className="col-12 mb-3 text-right">
                      <a href={Config.ConfigAppUrl+"sadminc/estadisticas/listadopartidos?token="+queryStringParams.token} className="btn btn-primary">Gestionar partidos</a>
                      </div>:false
              }
              <div className="col">
                {!open?<Table
                  thead={thead}
                  data={data}
                  setOpen={setOpen}
                />:false}
              </div>
            </div>
          </div>
        </>
}
export default App
