import { BrowserRouter, Route, Switch  } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import ListadoUsuarios from './ListadoUsuarios';
import ListadoTarifas from './listado_tarifas';
import ListadoProfesoresReemplazo from './listado_profesores_reemplazo';





const App=(props)=>{
  return  <StateContext.Provider value={{}}>
           <BrowserRouter>
            <Switch>
              <Route exact path={"/sadminc/gestion/listado_tarifas"} render={()=><ListadoTarifas/>}/>
              <Route exact path={"/sadminc/gestion/listado_profesores_reemplazo"} render={()=><ListadoProfesoresReemplazo/>}/>
              <Route exact path={"/sadminc/gestion/fotos_alumnos"} render={()=><ListadoUsuarios/>}/>
            </Switch>
          </BrowserRouter>
        </StateContext.Provider>
}

export default App;
