import React,{useState,useEffect} from 'react';
import Functions from '../../helpers/Functions';
import Config from '../../helpers/Config';
import Table from '../../screens/tablePaginadorProfesores';
import Input from '../../screens/inputs';
import Textarea from '../../screens/textarea';
import Select from '../../screens/select';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString       from 'query-string';
const  queryStringParams = queryString.parse(window.location.search);

//WEEKDAY mysql


let id=0
let si_no =   [
                {
                  label:"Si",
                  value:"Si",
                },
                {
                  label:"No",
                  value:"No",
                },
              ]
const App=(props)=>{
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [lista, setLista] = useState([]);
  const [q, setQ] = useState(false);

  const onChangeQ=(e)=>{
    if (e.target.value!=='') {
      setQ(e.target.value)
    }else {
      setQ(false)
    }
    getInit()
  }

  const getInit=()=>{
    let data          =   {token:queryStringParams.token}
        if (parseInt(queryStringParams.filter)>0) {
          data.filter = parseInt(queryStringParams.filter)
        }
        if (q) {
          data.q  = q
        }
    Functions.PostAsync("Puente","gruposEntrenamientos",data,{},{name:"callbackInit",funct:callbackInit})
  }

  const callbackInit=(data)=>{
    if (data.response.data) {
      setData(data.response.data)
      setData2(data.response.grupos)
    }
  }

  useEffect(() => {
    getInit()
  },[queryStringParams.token])

  useEffect(() => {
    if (!open) {
      //getInit()
    }
    if (open.grupos_asignados!==undefined) {
      setLista(open.grupos_asignados)
    }
    if (open) {
      setInputs(open)
    }
  },[open])


  const onSubmit2=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.profesor_id          =   open.idusuario
    Functions.PostAsync("Puente","saveGruposEntrenamiento",data,{},{name:"callbackContinue2",funct:callbackContinue2})
  }

  const callbackContinue2=(data)=>{
    setOpen(false)
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    let data                      =   {...inputs}
        data.token                =   queryStringParams.token
        data.profesor_id          =   open.idusuario
    Functions.PostAsync("Puente","asignarProfesor",data,{},{name:"callbackContinue",funct:callbackContinue})
  }

  const callbackContinue=(data)=>{
    setLista(data.response.data)
    getInit()
  }

  const onChange=(e)=>{
    let inputs_ =   inputs;
      inputs_[e.target.name]=e.target.value
      setInputs(inputs_)
  }

  let thead = [
      {
        label:"#",
        value:"#",
      },
      {
        label:"Grupo",
        value:"label",
      },
      {
        label:"Asignar",
        value:"editar",
      },
  ]

  const deleteGrupo=(row)=>{
    let data       =   {}
        data.token =   queryStringParams.token
        data.id    =   row.id
    Functions.PostAsync("Puente","deleteAsignacionProfesor",data,{},{name:"callbackContinue3",funct:callbackContinue3})
  }

  const callbackContinue3=(data)=>{
    setLista(data.response.data)
  }

  const Form=(props)=>{
    return    <div className="card">
                <div className="card-header">
                  Gestionar Usuarios
                </div>
                <div className="card-body">
                  <form onSubmit={onSubmit2}>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Nombres
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.label}
                              required={true}
                              data={data2}
                              name="label"
                              placeholder="Nombre del grupo"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Año
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.ano}
                              required={true}
                              data={data2}
                              name="ano"
                              placeholder="Año"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Cantidad
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.cantidad}
                              required={true}
                              data={data2}
                              name="cantidad"
                              placeholder="Cantidad"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Grupo
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.grupo}
                              required={true}
                              data={data2}
                              name="grupo"
                              placeholder="Grupo"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Fecha de inicio
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.fecha_inicio}
                              required={true}
                              data={data2}
                              name="fecha_inicio"
                              type="date"
                              placeholder="Fecha inicio"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Fecha finalización
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Input
                              className="form-control"
                              defaultValue={open.fecha_final}
                              required={true}
                              data={data2}
                              name="fecha_final"
                              type="date"
                              placeholder="Fecha final"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Día 1 de la semana
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Select
                              defaultValue={open.dia1}
                              required={true}
                              data={Functions.dias_semana}
                              name="dia1"
                              selectDefault="Día 1"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Día 2 de la semana
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Select
                              defaultValue={open.dia2}
                              required={true}
                              data={Functions.dias_semana}
                              name="dia2"
                              selectDefault="Día 2"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Día 3 de la semana
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Select
                              defaultValue={open.dia3}
                              name="dia3"
                              data={Functions.dias_semana}
                              selectDefault="Día 3"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 border-right text-right">
                        Cancha
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <Select
                              defaultValue={open.cancha}
                              required={true}
                              data={Functions.canchas}
                              name="cancha"
                              selectDefault="Cancha"
                              onChange={props.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-3 border-right text-right">

                      </div>
                      <div className="col">
                        <button type="submit" className="btn btn-primary mr-2">
                          Guardar cambios
                        </button>
                        <div className="btn btn-secondary" onClick={()=>setOpen(false)}>
                          Cerrar
                        </div>
                      </div>
                    </div>
                  </form>
                  {open.perfil==='11'?<>
                    <form onSubmit={onSubmit}>
                      <div className="card mt-4">
                        <div className="card-header bg-green text-white">
                          Asignar grupos de usuarios
                        </div>
                        <div className="card-body">

                            <div className="row">
                              <div className="col-3 border-right text-right">
                                Formulario
                              </div>
                              <div className="col">
                                <div className="row">
                                  <div className="col">
                                    <Select
                                      defaultValue={open.ver_resultados_encuesta}
                                      required={true}
                                      data={data2}
                                      name="grupo"
                                      selectDefault="Seleccione"
                                      onChange={props.onChange}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <button className="btn btn-primary">Asignar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {props.lista.length>0?<>
                              <div className="row">
                                <div className="col-12 mb-2">
                                  Grupos asignados
                                </div>
                                <div className="col-12">
                                  <table className="table">
                                    <thead>
                                      <th>Grupo</th>
                                      <th width="50">Acción</th>
                                    </thead>
                                    <tbody>
                                      {lista.map((row,key)=>{
                                        return  <tr key={key}>
                                                  <td>{row.grupo}</td>
                                                  <td><FontAwesomeIcon className="cursor-pointer" icon={faTrash} onClick={()=>deleteGrupo(row)}/></td>
                                                </tr>
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>:false}
                        </div>
                      </div>
                    </form>
                  </>:false}
                </div>
              </div>

  }

  return  <div className="container-fluid">
            <div className="row pt-2">
              <div className="col">
                {!open?<Table
                  onChangeQ={onChangeQ}
                  thead={thead}
                  data={data}
                  token={queryStringParams.token}
                  setOpen={setOpen}
                />:<Form  inputs={inputs}
                          setData2={setData2}
                          open={open}
                          lista={lista}
                          data2={data2}
                          onChange={onChange} />}
              </div>
            </div>
          </div>
}
export default App
